@import "../../../../static/colors";


.tag {
  padding: 4px;
  border-radius: 3px;
  text-transform: uppercase;
  color: $white;
  width: max-content;

  &.yellow {
    background: $honeyYellow;
  }

  &.red {
    background: $radicalRed;
  }
}


.modalWrap {
  display: flex;
  flex-direction: column;
  width: 524px;

  p {
    margin-bottom: 16px;
  }
}