@import "./src/static/colors";

.wrap {
  display: flex;
  flex-direction: column;
  position: relative;
}

.viewArea {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 370px;
  width: 100%;
  background: #EEEEEE;
  min-height: 52px;
  border-radius: 6px;
  padding: 8px 16px;
  box-sizing: border-box;
  cursor: pointer;
  gap: 16px;
}

.icon{
  flex-shrink: 0;
}

.title {
  margin-bottom: 16px;
}

.dropdownWrap {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 4px;
  padding: 4px;
  width: 100%;
  border-radius: 6px;
  background: white;
  overflow: auto;
  z-index: 100;

  position: absolute;
  top: calc(100% + 4px);
  left: -4px;
  box-shadow: 0 11px 22px -12px rgba(60, 74, 228, 0.25);

}

.singleOption {
  border-radius: 6px;
  width: 100%;
  padding: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: .2s;
  box-sizing: border-box;

  &:hover {
    background: #EEEEEE;
  }
}
