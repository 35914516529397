@import "./src/static/colors";

.topNavbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  padding: 16px 24px;
  position: sticky;
  top: 0;
  background: $white;
  z-index: 1000;
  border-bottom: 1px solid $line2;


  .header {
    color: $blue;
  }

  .rightPart {
    display: flex;
    align-items: center;

    .icons {
      display: flex;
      align-items: center;
      margin-right: 16px;
      position: relative;

      .iconWrap {
        height: 40px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $line2;
        border-radius: 6px;
        margin-right: 16px;


        svg{
          cursor: pointer;
          transition: .3s;
        }

        &:hover svg{
          transform: rotate(15deg);
        }
      }

      svg {
        height: 24px;
        width: 24px;

      }
    }

    .infoWrap {
      display: flex;
      flex-direction: column;
      margin-right: 16px;

      p {
        margin-top: 4px;
        color: $lightPink;
      }
    }

    .picture {
      height: 40px;
      width: 40px;
      border-radius: 6px;
      background: $line2;
    }
  }
}

.profileWrap {
  background: $white;
  width: 380px;
  border-radius: 12px;
  box-shadow: 5px 5px 10px rgba(60, 74, 228, 0.1);
  border: 1px solid #E6F1FF;
  z-index: 1000;
  position: absolute;
  top: 52px;
  padding: 16px;
  right: 16px
}
