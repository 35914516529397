@import "./src/static/colors";

.wrap {
  display: flex;
  flex-direction: column;
  gap: 24px;

  padding: 16px;
  border: 1px solid $aliceBlue;
  border-radius: 12px;


  .cols {
    display: grid;
    grid-template-columns: 1fr 340px;
    grid-gap: 32px;

    .col {
      display: flex;
      flex-direction: column;
      gap: 16px;

      p {
        color: $black2
      }
    }
  }
}

.headerWrapper{
  display: flex;
  align-items: center;
  gap: 8px;
}