@import "./src/static/colors";

.wrap{
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 524px;

  .color{
    color: $blue;
  }
}