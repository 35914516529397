@import "./src/static/colors";

.tableTags {
  display: flex;
  margin-top: -4px;
  flex-wrap: wrap;
  flex-shrink: 0;

  .tag {
    margin-top: 4px;
    margin-right: 4px;
    padding: 4px 8px;
    background: $aliceBlue;
    border-radius: 6px;
    color: $blue;
    word-break: break-all;
  }
}


.statusTag {
  padding: 8px 16px;
  border-radius: 3px;
  color: $eerieBlack;
  display: flex;
  justify-content: center;
  max-width: 120px;

  &.yellow {
    background: $honeyYellow;
  }

  &.blue {
    background: $blue;
    color: $white;
  }

  &.red {
    background: $radicalRed;
    color: $white;
  }
}

.dotline {
  width: 100%;
  border-bottom: 2px dashed $line2;
  min-width: 16px;
  flex-shrink: 1;
}

.navHeader {
  display: flex;
  align-items: center;

  .backButton {
    margin-right: 16px;
    cursor: pointer;
    height: 40px;
    width: 40px;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}