@import "./src/static/colors";

.wrap {
  display: flex;
  flex-direction: column;


  .countersGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 24px;

    margin-bottom: 24px;
  }
}

.tableTags {
  display: flex;
  margin-top: -4px;
  flex-wrap: wrap;

  .tag {
    margin-top: 4px;
    margin-right: 4px;
    padding: 4px 8px;
    background: $aliceBlue;
    border-radius: 6px;
    color: $blue;
  }
}


.statusTag {
  padding: 8px 16px;
  border-radius: 3px;
  color: $eerieBlack;
  display: flex;
  justify-content: center;
  max-width: 120px;


  &.yellow {
    background: $honeyYellow;
  }

  &.blue {
    background: $blue;
    color: $white;
  }

  &.red {
    background: $radicalRed;
    color: $white;
  }
}