@import "./src/static/colors";

.wrap {
  display: flex;
  flex-direction: column;

  .topLine {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;

    margin-bottom: 24px;
  }
}



.tag {
  padding: 4px;
  border-radius: 3px;
  text-transform: uppercase;
  color: $white;
  width: max-content;

  &.blueTag {
    background: $blue;
  }

  &.yellowTag {
    background: $honeyYellow;
  }

  &.blackTag {
    background: $black2;
  }
}

.createWrap {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  width: 524px;
}
