@import "../../../../static/colors";

.tabNavbar {
  display: grid;
  grid-template-columns: 1fr ;
  align-items: flex-start;
  grid-gap: 16px;
  position: relative;

  .tabs {
    width: 100%;
    flex-shrink: 1;
    display: flex;
    overflow-x: auto;
    position: relative;
    align-items: center;
    scroll-behavior: smooth;

    padding-bottom: 8px;


    .tab {
      color: $blue;
      background: $white;
      transition: .2s;
      border: none;
      border-radius: 12px;
      padding: 16px 32px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      user-select: none;
      -webkit-user-drag: none;
      word-break: keep-all;
      white-space: nowrap;


      &.active {
        background: $aliceBlue;
      }

      &:hover {
        background: $aliceBlueHover;
      }

      &:not(:last-child) {
        margin-right: 16px;
      }
    }
  }

  .actionButton {
    color: $white;
    background: $blue;
    border-radius: 6px;
    transition: .2s;
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    height: 41px;
    cursor: pointer;

    p {
      margin-right: 8px;
    }

    &:hover {
      background: $aquaBlueHover;
    }


  }

  .actionDropdown {
    position: absolute;
    right: -4px;
    top: calc(100% + 8px);
    background: $white;
    padding: 4px;
    border: 1px solid $white;
    box-sizing: border-box;

    box-shadow: 0 11px 22px -12px rgba(60, 74, 228, 0.25);
    border-radius: 6px;

    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 4px;

    .actionOption {
      border: none;
      background: $white;
      transition: .2s;
      color: $blue;
      cursor: pointer;
      padding: 8px;

      &:hover {
        background: $aliceBlue;
      }
    }

  }
}

.tabContent {
  display: flex;
  flex-direction: column;

  box-sizing: border-box;
  width: 100%;
  transition: .3s;
  margin-top: 32px;


  min-height: calc(90vh - 242px);
}

.scrollbarWrap {
  position: relative;
  display: flex;
  align-items: center;
  flex-shrink: 1;
  overflow: auto;
  padding-right: 40px;
  padding-left: 40px;


  .leftButton {
    position: absolute;
    left: 0;
  }

  .rightButton {
    position: absolute;
    right: 0;
  }

  .rightButton, .leftButton {
    background: none;
    border: none;
    cursor: pointer;
    top: 12px;

    path {
      fill: $blue;
    }
  }
}