@import "./src/static/colors";

.admin {
  display: flex;
  min-height: 100vh;
  font-family: 'Inter', sans-serif;
}

.contentContainer {
  padding: 24px;
  width: 100%;
  box-sizing: border-box;
}

.topNavbarWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.simplebar-scrollbar:before {
  background: #202993;
}

.recharts-default-legend {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
  justify-items: left;
  height: 100%;
  overflow-y: auto;

  li {
    display: flex !important;
    align-items: center;

    span {
      text-align: left;
      font-size: 11px;
    }
  }

  svg {
    height: 25px;
    width: 25px;
    min-height: 25px;
    min-width: 25px;
    border-radius: 6px;
  }
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border: none;
  padding: 0;
  margin: 0;
}

::-webkit-scrollbar-track {
  background: none;
}

::-webkit-scrollbar-thumb {
  background: $blue;
  transition: .3s;
  border-radius: 3px;

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $aquaBlueHover;
}


.ql-editor{
  min-height: 400px;
  max-height: 400px;
  overflow: auto;
}
