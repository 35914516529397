@import "./src/static/colors";


.coloredText {
  color: $lightPink;
}

.emailConfirm {
  color: $white;
  padding: 4px;
  background: $radicalRed;
  margin-left: 8px;
  border-radius: 2px;
}

.detailsWrap {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  width: 100%;

  .detailItem {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 4px;

    .detailsEditWrap {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .detailsEditButton {
        height: 16px;
        width: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: none;
        border: none;
        cursor: pointer;

        &:hover svg {
          transform: translateX(3px);
        }

        svg {
          transition: .3s;
        }

        svg path {
          transition: .3s;
        }

        &:hover svg path {
          fill: $aquaBlueHover;
        }
      }
    }
  }
}
