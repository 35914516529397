@import "./src/static/colors";

.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.emptyStat {
  width: 100%;
  height: 300px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $line2;
}

.headerWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}