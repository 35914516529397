@import "../../../../../static/colors";

.wrap {
  position: relative;
  width: max-content;
  justify-self: flex-end;
}

.actionDropdownWrap {
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  border: 1px solid #FFFFFF;

  box-shadow: 0 11px 22px -12px rgba(60, 74, 228, 0.25);
  border-radius: 6px;

  position: absolute;
  right: -4px;
  top: calc(100% + 8px);
  z-index: 100;
  padding: 4px;
  transition: .2s;
  min-width: 100%;


  .dropdownButton {
    background: none;
    border: none;
    color: $blue;
    cursor: pointer;
    border-radius: 6px;
    text-align: left;
    transition: .2s;
    padding: 10px;
    white-space: nowrap;

    &:hover {
      background: $aliceBlueHover;
    }
  }
}


.actionButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  height: 41px;
  min-width: 142px;
  border-radius: 6px;
  transition: .2s;
  cursor: pointer;
  background: none;
  padding: 8px 16px;

  &.main {
    background: $blue;
    color: $white;

    &:hover {
      background: $aquaBlueHover;
    }

    svg {
      margin-left: 12px;
    }
  }

  &.secondary {
    background: $aliceBlue;
    color: $blue;

    &:hover {
      background: $aliceBlueHover;
    }

    svg {
      margin-left: 12px;
    }
  }

  &.red {
    background: $radicalRed;
    color: $white;

    &:hover {
      background: $radicalRedHover;
    }

    svg {
      margin-right: 12px;
    }
  }

  &.icon {
    min-width: 41px;
    transition: .3s;
    border: 1px solid transparent;
    padding: 0;
    background: white;

    svg path{
      transition: .3s;
    }

    &:hover {
      border: 1px solid $line2;

      svg path{
        fill: $aquaBlueHover;
      }
    }
  }

  &.altIcon {
    min-width: 41px;
    transition: .3s;
    border: 1px solid transparent;
    padding: 0;


    svg path{
      transition: .3s;
    }

    &:hover {


      svg path{
        fill: $aliceBlueHover;
      }
    }
  }

  &.iconCustom{
    min-width: 0;
  }
}
