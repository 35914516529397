@import "./src/static/colors";

.wrap {
  display: flex;
  flex-direction: column;

  .topLine {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 24px;
  }

  .table {
    display: flex;
    flex-direction: column;

    .tableLines {
      display: flex;
      flex-direction: column;
    }
  }

  .topButtons {
    display: flex;

    button {
      margin-right: 16px;
    }
  }
}

.cardsWrap {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 24px;

  margin-bottom: 24px;
}

.cardModal {
  display: flex;
  flex-direction: column;
  width: 524px;

  p {
    margin-bottom: 16px;
  }
}