@import "./src/static/colors";

.wrap {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 24px;
}

.emptyList {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 191px;

  border-radius: 12px;
  background: $line2;
}
