@import "./src/static/colors";

.wrap {
  display: flex;
  gap: 16px;
  width: 100%;

  .col1 {
    flex: 1;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .col2 {
    flex: 2;
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex-shrink: 0;
  }
}

.subjectIcon {
  height: 40px;
  width: 40px;
  object-fit: contain;
}

.unionStatus {
  color: white;
  padding: 4px;
  border-radius: 3px;
  text-transform: uppercase;
  font-weight: 600;
  width: max-content;

  &.active {
    background: $honeyYellow;
  }

  &.inactive {
    background: $radicalRed;
  }
}

.selectedSubjectLabel{
  color: $blue;
}