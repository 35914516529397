@import "./src/static/colors";

.button{
  border-radius: 6px;
  display: flex;
  align-items: center;
  background: none;
  border: none;
  height: 48px;
  cursor: pointer;
  justify-content: center;


  padding: 0 32px;
  transition: .2s;

  &.smallPadding {
    padding: 0 16px;
  }

  &:active{
    transform: translateY(2px);
  }

  &.main{
    background: $blue;
    color: $aliceBlue;

    path{
      fill: $aliceBlue;
      transition: .2s;
    }

    &:hover{
      background: $aquaBlueHover;
    }
  }

  &.secondary{
    background: $aliceBlue;
    color: $blue;

    path{
      fill: $blue;
      transition: .2s;
    }

    &:hover{
      background: $aliceBlueHover;

    }
  }

  &.alert{
    background: $radicalRed;
    color: $aliceBlue;

    path{
      color: $aliceBlue;
      transition: .2s;
    }

    &:hover{
      background: $radicalRedHover;
    }
  }

  &.disabled{
    background: $text2;
    color: $white;

    path{
      color: $aliceBlue;
      transition: .2s;
    }

    &:hover{
      background: $text2;
    }
  }

  &.lightAlert{
    background: transparent;
    color: $lightPink;
    border: 1px solid $lightPink;
    flex-direction: row-reverse;
    height: 41px;

    .icon{
      margin-right: 0;
      margin-left: 8px;
    }
  }

  &.alertBorder{
    background: transparent;
    color: $radicalRed;
    border: 1px solid $radicalRed;
    flex-direction: row-reverse;
    height: 48px;
  }

  .icon{
    width: 21px;
    height: 21px;
    display: flex;
    align-items: center;
    justify-content: center;

    margin-right: 8px;
  }

  &.small{
    height: 36px;
  }
}