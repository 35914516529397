.wrap {
  display: flex;
  flex-direction: column;
  gap: 16px;

  margin-bottom: 64px;
  min-height: 400px;
}

.avatar {
  height: 40px;
  width: 40px;
  border-radius: 6px;
  object-fit: cover;
}