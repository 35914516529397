@import "./src/static/colors";

.description{
  margin-bottom: 32px;
  color: $text2;
}

.stage1{
  display: flex;
  flex-direction: column;

  .stage1Inputs{
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 24px;
    margin-bottom: 16px;
  }

  .doubleRow{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;
  }

  .notEqual{
    position: absolute;
    top: 0;
    right: 0
  }
}

.stage2{

}