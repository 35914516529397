@import "./src/static/colors";

.wrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;



  .infoColumn {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 100%;
    gap: 16px;
  }

  .users {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;
  }

  .infoRow {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
  }

  .stats {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 24px;
    margin-top: 24px;

    .infoColumn {
      gap: 8px;
    }

    h6 {
      color: $black2;
    }
  }
}





