@import "./src/static/colors";

.emptyList {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background: $line2;
  padding: 64px;

  width: 100%;
  height: 100%;
}
