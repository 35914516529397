@import "./src/static/colors";

.rowImg {
  height: 40px;
  width: 40px;
  border-radius: 3px;
  overflow: hidden;
  object-fit: cover;
}

.status {
  color: white;
  padding: 4px;
  border-radius: 3px;
  text-transform: uppercase;
  width: max-content;

  &.active {
    background: $honeyYellow;
  }

  &.inactive {
    background: $radicalRed;
  }
}

.wrap{
  display: flex;
  flex-direction: column;
  gap: 16px;
}