@import "./src/static/colors";

.wrap {
  display: flex;
  padding: 24px;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  background: $line2;
  transition: .3s;
  border-radius: 12px;

  &.blue{
  background: $aliceBlue;

    .tag{
      background: $lightPink;
    }
    &:hover {
      background: $aliceBlueHover;
    }
}

  &.grey{
    background: $line2;

    .tag{
      background: $aliceBlueHover;
    }
    &:hover {
      background: $aliceBlue;
    }
  }

  &:hover{
    .icon{
      transform: scale(1.05) translate(4px,4px) rotate(4deg);
    }
  }

  .icon {

    transition: .3s;
  }

  .statInfoWrap {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .topLine {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
    }

    .tag {
      padding: 8px 16px;
      border-radius: 3px;
      color: $blue;
    }

    .cardTitle {
      color: $blue;
      margin-top: 8px;
      text-align: right;
    }

    .cardCounter, span {
      color: $blue;
    }

    .cardAction {
      margin-right: -16px;
    }
  }
}