@import "./src/static/colors";

.loader {
  padding: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  max-height: 100%;

  &.big {
    border-radius: 12px;
    background: $line2;
    width: 100%;
    height: 500px;

    display: flex;
    align-items: center;
    justify-content: center;

  }
}