@import "./src/static/colors";

.wrap {
  border-radius: 12px;
  background: #FFFFFF;
  border: 1px solid $aliceBlue;
  box-shadow: 5px 5px 10px rgba(60, 74, 228, 0.1);

  position: absolute;
  right: 16px;
  top: 52px;
  width: 296px;
  padding: 16px;

  .container{
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8px;
    margin-top: 24px;
  }
}


.rowWrap {
  display: flex;
  width: 100%;
  padding-bottom: 8px;
  position: relative;
  justify-content: space-between;

  &:after{
    width: 80%;
    position: absolute;
    height: 1px;
    background: $line2;
    content: "";
    bottom: -1px;
    left: 10%;
  }

  .rowInfo {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-left: 18px;

    &:before {
      background: $blue;
      content: "";
      height: 10px;
      width: 10px;
      border-radius: 100%;
      position: absolute;
      left: -18px;
      top: 0;
    }

    .rowTitle {
      font-size: 12px;
      font-weight: 600;
      margin-bottom: 4px;
    }

    .rowCaption {
      color: $black2;
    }
  }

  img{
    height: 40px;
    width: 40px;
    background: $blue;
    border-radius: 100%;
    margin-left: 8px;
  }
}

