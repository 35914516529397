@import "./src/static/colors";

.wrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;

  .infoCol {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .buttons {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 24px;
    }

    .historyWrap {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }

  }

  .orderInfo {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}


.dropdownWrap {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .header {
    display: flex;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
    border-radius: 12px;
    background: $aliceBlue;
    border: none;
    cursor: pointer;


    h4 {
      color: $blue;
    }

    .icon {
      transition: .3s;
      height: 24px;
      width: 24px;
      display: flex;
      align-items: center;
      justify-content: center;

      &.open {
        transform: rotate(180deg);
      }
    }
  }


  .content {
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    & > div:not(:last-child) {
      padding-bottom: 24px;
      border-bottom: 1px solid $line2;
    }
  }
}

.modalWrap {
  width: 524px;
  max-width: 524px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: relative;

  .radioWrap {
    display: flex;
    flex-direction: column;
  }
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $line2;
  opacity: .7;
  position: absolute;
  top: -8px;
  left: -8px;
  right: -8px;
  bottom: -8px;
  border-radius: 8px;
}