@import "./src/static/colors";


.bg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(26, 26, 26, 0.25);;


  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.wrap {
  display: flex;
  flex-direction: column;
  background: #FFFFFF;

  border: 1px solid $aliceBlue;
  box-sizing: border-box;

  box-shadow: 0 11px 22px -12px rgba(60, 74, 228, 0.25);
  border-radius: 12px;

  padding: 24px;

  align-items: center;


}

.content {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.titleRow {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.closeButton {
  color: $white;
  border: none;
  cursor: pointer;
  margin-left: 32px;
  padding: 8px 16px;
  background: $radicalRed;
  border-radius: 6px;
  transition: .3s;

  &:hover {
    background: $radicalRedHover;
  }
}

.modalButtons {
  display: flex;
  gap: 16px;
  margin-top: 32px;
}