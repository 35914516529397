@import "./src/static/colors";

$template: 1fr 2fr 2fr 92px;

.tableWrap {
  display: flex;
  flex-direction: column;

  .tableHeader {
    display: grid;
    grid-template-columns: $template;
    grid-gap: 16px;
    padding: 32px 16px;

    background: $line2;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;

    p {
      color: $tableHeader;
    }
  }

  .tableRows {
    display: flex;
    flex-direction: column;
  }

}

.tableRow {
  padding: 16px;

  display: grid;
  grid-template-columns: $template;
  grid-gap: 16px;
  align-items: center;

  &:nth-child(2n) {
    background: $line2;
  }

  &:last-child {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
}

.viewWrap {
  width: 824px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
  margin-bottom: 24px;
}