@import "./src/static/colors";

.deleteWrap {
  max-width: 523px;
}


.BlockUserInfoWrap {
  width: 664px;

  .info {
    display: flex;
    flex-direction: column;
    gap: 32px;

    .cols {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 32px;
    }

    .block {
      display: flex;
      flex-direction: column;
      gap: 8px;


      h5 {
        color: $text2;
      }
    }
  }
}