.wrap {
  width: 40px;
  height: 40px;
  border-radius: 6px;
  margin-right: 16px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  svg{
    width: 70%;
    height: 70%;

    path{
      fill: white;
    }

  }
}