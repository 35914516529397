
.viewWrap {
  width: 524px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  margin-bottom: 32px;

  max-height: 70vh;
  overflow: auto;
}