@import "./src/static/colors";

.wrap {
  display: flex;
  flex-direction: column;

  .title {
    margin-bottom: 24px;
  }
}

.modalWrap {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 824px;

  .reviewInfo {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    .col{
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }
}