@import "./src/static/colors";

.block {
  display: flex;
  flex-direction: column;
  gap: 16px;


  .infoList {
    display: flex;
    flex-direction: column;
    gap: 16px;

    h6 {
      color: $black2;
    }

    .row {
      display: flex;
      justify-content: space-between;

      gap: 16px;
      width: 100%;

      h6, p {
        flex-shrink: 0;
      }
    }

    .col {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }
}