@import "./src/static/colors";

.wrap {
  display: flex;
  flex-direction: column;

  .topbar {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .buttons {
      display: flex;

      > button {
        margin-right: 16px;
      }
    }

  }

  .tabs {
    display: flex;
    align-items: center;


    button {
      background: none;
      border: none;
      margin-right: 16px;
    }

    .tab {
      color: $eerieBlack;
      position: relative;
      cursor: pointer;

      &:after {
        height: 3px;
        border-radius: 6px;
        width: 100%;
        background: $blue;
        transition: .3s;
        opacity: 0;
        transform: scaleX(0);
        content: '';
        position: absolute;
        bottom: -6px;
        left: 0;
      }

      &.active {
        color: $blue;

        &:after {
          opacity: 1;
          transform: scaleX(1);
        }
      }
    }
  }
}