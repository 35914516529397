
.fadeIn {
  animation: .2s forwards fadein;
}

.fadeOut {
  animation: .2s forwards fadeout;
}

@keyframes fadein {
  0% {
    opacity: 0;
    transform: translateY(5px) scale(.99);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

@keyframes fadeout {
  0% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
  100% {
    opacity: 0;
    transform: translateY(5px) scale(.98);
  }
}