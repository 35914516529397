@import "./src/static/colors";

.wrap {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 24px;
}

.line {
  display: flex;
  align-items: center;
  gap: 16px;
}

.areaChartWrap {
  background: $white;
  border: 1px solid $aliceBlue;
  border-radius: 12px;
  padding: 16px;
  grid-column-start: 1;
  grid-column-end: 3;

  display: flex;
  flex-direction: column;
  gap: 24px;

  .headline {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .type {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }
  }
}

.radialChartWrap {
  display: flex;
  flex-direction: column;
  gap: 24px;
  background: #FFFFFF;
  border: 1px solid #E6F1FF;
  border-radius: 12px;
  padding: 16px;
}

.headerWrapper{
  display: flex;
  align-items: center;
  gap: 8px;
}