@import "../../../../static/colors";


.tagContainer {
  display: flex;
  margin-top: -4px;
  flex-wrap: wrap;

  .tagWrap {
    .tag {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $blue;
      background: $aliceBlue;
      border-radius: 6px;
      padding: 4px 8px;
      margin-top: 4px;
      margin-right: 4px;
      max-height: max-content;
    }
  }
}


.typeSelectorWrap {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;

  p {
    margin-right: 16px;
  }
}

.headerLine {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}
