@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');
@import "./src/static/colors";

a, p, span, h1, h2, h3, h4, h5, h6, li, input, textarea, button, tspan{
  font-family: 'Inter', sans-serif;
  margin: 0;
  padding: 0;
  line-height: 100%;
  color: $eerieBlack;
}

p{
  word-break: break-word;
}

tspan{
  font-weight: 600;
  font-size: 10px;
  line-height: 100%;
}

.lightH1{
  font-size: 93px;
  font-weight: 300;
  letter-spacing: -1.5px;
}

.boldH1{
  font-size: 93px;
  font-weight: 700;
  letter-spacing: -1.5px;
}

.lightH2{
  font-size: 58px;
  font-weight: 300;
  letter-spacing: -0.5px;
}

.boldH2{
  font-size: 58px;
  font-weight: 700;
  letter-spacing: -0.5px;
}

.lightH3{
  font-size: 47px;
  font-weight: 400;
  letter-spacing: 0;
}

.boldH3{
  font-size: 47px;
  font-weight: 700;
  letter-spacing: 0;
}

.lightH4{
  font-size: 33px;
  font-weight: 400;
  letter-spacing: 0.25px;
}

.boldH4{
  font-size: 33px;
  font-weight: 700;
  letter-spacing: 0.25px;
}

.lightH5{
  font-size: 23px;
  font-weight: 400;
  letter-spacing: 0;
}

.boldH5{
  font-size: 23px;
  font-weight: 600;
  letter-spacing: 0;
}

.lightH6{
  font-size: 19px;
  font-weight: 500;
  letter-spacing: 0.15px;
}

.boldH6{
  font-size: 19px;
  font-weight: 600;
  letter-spacing: 0.15px;
}

.subtitle1Light{
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.15px;
}

.subtitle1Bold{
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.15px;
}

.subtitle2Light{
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.1px;
}

.subtitle2Bold{
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.1px;
}

.body1{
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.5px;
}

.body2{
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.25px;
}

.button{
  font-size: 16px;
  font-weight: 600;
}

.caption{
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.4px;
}

.overline{
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}