@import "./src/static/colors";

.wrap{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: available;

  svg{
    height: 50px;
    width: 50px;

    path{
      fill: $blue;
    }
  }
}