@import "./src/static/colors";

.wrap {
  width: 824px;
  min-height: 439px;
  max-height: 700px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .cols {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;
    margin-top: 24px;
    box-sizing: border-box;

  }

  .col {
    display: flex;
    flex-direction: column;
    overflow: auto;
    max-height: 540px;
    position: relative;

    h6 {
      margin-bottom: 16px;
    }
  }
}

.form{
  display: flex;
  flex-direction: column;
}

.singleOption {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  cursor: pointer;
}

.singleCompetency {
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 6px;
  margin-bottom: 8px;
  cursor: pointer;
  transition: .3s;

  &:hover {
    background: $aliceBlueHover;
  }

  &.active {
    background: $aliceBlue;
  }
}

.mark {
  height: 25px;
  width: 25px;
  border: 1px solid $blue;
  position: relative;
  border-radius: 100%;
  margin-right: 16px;
  flex-shrink: 0;

  &:after {
    position: absolute;
    content: "";
    height: 13px;
    width: 13px;
    transition: .3s;
    transform: scale(.4);
    opacity: 0;
    background: $blue;
    border-radius: 100%;
    left: 5px;
    top: 5px;
  }

  &.active:after {
    transform: scale(1);
    opacity: 1;
  }
}

.selectAll{
  color: $text2;
  transition: .3s;
  background: none;
  border: none;
  margin-top: 8px;
  cursor: pointer;

  &:hover{
    color: $black2;
  }
}