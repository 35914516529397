@import "./src/static/colors";

.wrap {
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-height: 400px;
}

.block {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
}

.blockHeader {
  margin-bottom: 16px;

}

.items {
  display: flex;
  flex-wrap: wrap;
  margin-top: -16px;
}

.singleTag {
  background: $aliceBlue;
  border-radius: 6px;
  margin-right: 16px;
  margin-top: 16px;
  color: $blue;
  padding: 8px 16px;
}

.headLine {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.avatar {
  height: 40px;
  width: 40px;
  background: $line2;
  border-radius: 6px;
}

.switch {
  display: flex;

  .switchButton {
    margin-right: 16px;
    background: none;
    position: relative;
    cursor: pointer;
    height: 36px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $lightPink;
    border: 1px solid $lightPink;
    border-radius: 6px;
    transition: .3s;

    svg {
      margin-left: 16px;
    }

    path {
      transition: .3s;
    }

    &:hover {
      border-color: $radicalRedHover;
      color: $radicalRedHover;

      path {
        fill: $radicalRedHover
      }
    }
  }
}
