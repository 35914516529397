@import "../../../../../static/colors";

.counter {
  position: absolute;
  right: 24px;
  top: 36px;

  color: $blue;
}

.chart {
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-weight: 400;
  height: 100%;
  position: relative;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  background: $white;
  opacity: .2;
  border-radius: 12px;
}