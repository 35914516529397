
.wrap {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.block {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.modalWrap {
  width: 524px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}