@import "./src/static/colors";


.verticalGrid{
  display: grid;
  grid-gap: 32px;
  grid-template-columns: 1fr;
}

.wrap{
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
}

.support{
  display: grid;
  grid-template-columns: 1fr 300px;
  grid-gap: 24px;
}