$blue: #3C4AE4;
$aquaBlueHover: #4151FF;
$radicalRed: #FF426C;
$radicalRedHover: #ff6185;
$honeyYellow: #FFB100;
$honeyYellowHover: #ffa100;
$lightPink: #FFB8BA;
$aliceBlue: #E6F1FF;
$aliceBlueHover: #D4E7FF;
$eerieBlack: #1A1A1A;
$white: #FFFFFF;
$text2: #A1A5B7;
$line2: #EFF2F5;
$black2: #3F4254;
$green: #44c95d;
$tableHeader : #3F4254;

