@import "./src/static/colors";

.icon {
  height: 40px;
  width: 40px;
  object-fit: contain;
}

.tag {
  padding: 4px;
  color: white;
  border-radius: 2px;
  text-transform: uppercase;


  &.blue {
    background: $blue;
  }

  &.yellow {
    background: $honeyYellow;
  }

  &.black {
    background: $black2;
  }
}

.wrap{
  display: flex;
}