@import "./src/static/colors";

.wrap{
  display: flex;
  width: 100%;
  gap: 16px;

  .col1, .col2{
    display: flex;
    flex-direction: column;
    gap: 16px;

    flex: 1;
  }
}


.sectionIcon{
  height: 40px;
  width: 40px;
  object-fit: contain;
}

.selectedSection{
  color: $blue;
}