@import "./src/static/colors";

.wrap {
  display: flex;
  flex-direction: column;
  gap: 24px;


  padding: 16px;
  border: 1px solid $aliceBlue;
  border-radius: 12px;

  .headLine {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
  }
}