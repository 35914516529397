@import "./src/static/colors";

.wrap {
  display: flex;
  flex-direction: column;


  .searchBar {
    margin-bottom: 24px;
    display: flex;
    width: 100%;


    .searchDates {
      margin-left: 24px;
      display: flex;
      align-items: center;


      span {
        margin: 8px;
      }
    }
  }
}

.tableTags {
  display: flex;
  margin-top: -4px;
  flex-wrap: wrap;

  .tag {
    margin-top: 4px;
    margin-right: 4px;
    padding: 4px 8px;
    background: $aliceBlue;
    border-radius: 6px;
    color: $blue;
  }
}

.status {
  width: 110px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  background: $blue;
  color: $white;
}

.editModalWrap {
  display: flex;
  flex-direction: column;
  width: 600px;

  .agentList {
    margin-top: 24px;
    min-height: 500px;
    max-height: 500px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .editButtons {
    display: flex;
    gap: 16px;
    align-self: center;
  }
}