.wrap {
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-height: 500px;

  .header {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .tables {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;
  }
}

.singleTableWrap {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.listAvatar {
  height: 40px;
  width: 40px;
  border-radius: 6px;
  object-fit: cover;
}