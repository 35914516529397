@import "./src/static/colors";

.chartWrap {
  display: flex;
  flex-direction: column;
  position: relative;

  .chartHeader {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 16px;
    margin-bottom: 16px;
    position: relative;

    &:after {
      height: 2px;
      width: 80%;
      content: "";
      position: absolute;
      left: 10%;
      bottom: -2px;
      background: $line2;
    }
  }
}

.radialChartWrap {
  position: relative;
  flex: 1;
}

.chartCounterWrap {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 180px;
  z-index: -1;


  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.emptyTickets {
  height: 300px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  background: $line2;
  border-radius: 12px;
}
