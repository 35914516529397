@import "./src/static/colors";

.wrap {
  box-sizing: border-box;
}

.headLine {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;
  position: relative;
  z-index: 105;
}

.paginationInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.searchBar {
  display: flex;
  align-items: center;

  label {
    width: 400px;
  }

  .resetSearchbar {
    margin-left: 16px;
  }
}

.searchCount {
  margin-top: 32px;
  margin-bottom: 24px;
}
