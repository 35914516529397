@import "./src/static/colors";

$template: 1fr 5fr 3fr 92px;

.wrap {
  display: flex;
  flex-direction: column;

  .content {
    display: flex;
    flex-direction: column;
    margin-top: 24px;


    .cards {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 24px;
      width: 100%;

      .card {
        padding: 32px;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;

        .psub {
          position: absolute;
          left: 16px;
          top: 16px;
          color: $lightPink;
        }

        h3 {
          color: $blue;
        }

        &.gradient {
          background: linear-gradient(90deg, #E9DEFA 0%, #FBFCDB 100%);
        }

        &.blue {
          background: $aliceBlue;
        }

        &.grey {
          background: $line2;
        }

        .cardText {
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          p {
            margin-top: 8px;
          }

          p, span {
            color: $blue;
          }

        }

        .icon {
          height: 100px;
          width: 100px;
          object-fit: cover;
        }
      }
    }
  }
}

.table {
  display: flex;
  flex-direction: column;
  margin-top: 24px;

  .tableHeader {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: $template;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;

    padding: 32px 16px;
    background: $line2;

    p {
      color: $tableHeader;
    }
  }

  .tableRows {
    display: flex;
    flex-direction: column;

    margin-bottom: 16px;

    .tableRowWrap {
      display: grid;
      grid-gap: 16px;
      grid-template-columns: $template;
      align-items: center;

      padding: 16px;

      &:nth-child(2n) {
        background: $line2;
      }

      &:last-child {
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
      }
    }
  }
}

.profileWrap{
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}