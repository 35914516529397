@import "./src/static/colors";


.modalWrap {
  width: 824px;
  min-height: 427px;
  display: flex;
  flex-direction: column;

  .cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;
  }

  .info {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 24px;


    h6 {
      color: $black2;
    }

    .col {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    .row {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: flex-end;

      h6, p{
        flex-shrink: 0;
      }
    }

    .row, .col {
      h6 {
        color: $black2;
      }
    }


    .ticketInfo {
      display: flex;
      align-items: center;

      p {
        margin-right: 16px;
      }
    }
  }

  .buttons {
    display: flex;
    gap: 16px;
    margin-top: 16px;
    align-self: center;
  }
}

.tag{
  padding: 4px;
  border-radius: 4px;
  color: $white;
  width: fit-content;

  &.success{
    background: $blue;
  }

  &.rejected{
    background: $radicalRed;
  }


  &.pending{
    background: $honeyYellow
  ;
  }
}