@import "./src/static/colors";


.infoWrap {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
}


.status {
  p {
    padding: 4px 8px;
    width: 80px;
    display: flex;
    justify-content: center;
    border-radius: 6px;

    &.active {
      background: $honeyYellow;
    }

    &.cancelled {
      background: $lightPink;
    }

    &.done {
      background: $aliceBlue;
    }
  }
}


.tagContainer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  gap: 4px;

  .tagWrap {
    .tag {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $blue;
      background: $aliceBlue;
      border-radius: 6px;
      padding: 8px;
      max-height: max-content;
    }
  }
}

.status {
  padding: 8px 16px;
  border-radius: 3px;
  color: $eerieBlack;
  display: flex;
  justify-content: center;

  &.yellow {
    background: $honeyYellow;
  }

  &.blue {
    background: $blue;
    color: $white;
  }

  &.red {
    background: $radicalRed;
    color: $white;
  }
}





