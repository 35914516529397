@import "./src/static/colors";


.bg{
  background: $blue;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.layout{
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 12px;
  background: $white;
  padding: 64px 128px;
}

.form{

  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
}

.button{
  margin-top: 24px;
}

.input{
  margin-top: 24px;
  width: 400px;
}