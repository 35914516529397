@import "./src/static/colors";

.wrap{
  display: grid;
  grid-gap: 24px;
  grid-template-columns: 1fr;
  margin-bottom: 32px;
  width: 524px;
}

.buttonsWrap{
  display: flex;
  gap: 16px;
}