@import "./src/static/colors";

.wrapStage {
  width: 824px;
  display: flex;
  flex-direction: column;
}

.wrapStage1Inputs {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
  margin-bottom: 32px;
}

.doubleRow {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
}

.tripleRow {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 24px;
}

.description {
  margin-bottom: 16px;
  color: $text2;
}

.wrongPasswordMessage {
  position: absolute;
  top: 0;
  right: 0;
}

.table {
  .tableHeader {
    color: $text2;
    display: grid;
    grid-template-columns: 40px 4fr 4fr 92px;
    grid-gap: 16px;
    margin-bottom: 8px;
  }

  .tableGrid {
    max-height: 500px;
    overflow: auto;

    .singleTableRow{
      display: grid;
      grid-template-columns: 40px 4fr 4fr 92px;
      grid-gap: 16px;
      margin-bottom: 8px;
      align-items: center;

      img{
        height: 40px;
        width: 40px;
        border-radius: 6px;
        background: $aliceBlue;
      }

      .deleteIcon{
        cursor: pointer;
        display: flex;
        justify-content: center;

        path{
          transition: .3s;
        }

        &:hover path{
          fill: $radicalRedHover;
        }
      }
    }
  }
}

.addSkillsBtn{
  display: flex;
  background: none;
  align-items: center;
  border: none;
  cursor: pointer;

  p{
    margin-left: 16px;
  }

  rect{
    transition: .3s;
  }

  &:hover rect{
    fill: $aquaBlueHover;
  }


}
