@import "./src/static/colors";



.wrap {
  display: flex;
  flex-direction: column;
  gap: 24px;

  padding: 16px;
  border-radius: 12px;
  background: $blue;

  color: $white;

  tspan {
    color: white;
    fill: white;
  }

  .headline {
    display: flex;
    flex-direction: column;
    gap: 16px;



    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .white {
        color: white;
      }

      .line{
        display: flex;
        align-items: center;
        gap: 16px;

        svg path{
          fill: white;
        }
      }


      .tabs {
        button {
          color: inherit !important;

          &:after {
            background: $white !important;
          }
        }
      }


      .selector {
        align-items: center;
        display: flex;
        gap: 8px;

        & > p {
          color: inherit;
        }
      }
    }
  }
}
