@import "./src/static/colors";

.block{
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;

  .title{
    margin-bottom: 16px;
    word-break: keep-all;
  }
}

.wrap {
  display: flex;
  align-items: center;


  .icon{
    height: 21px;
    width: 21px;
    display: flex;
    align-items: center;
    justify-content: center;

    margin-left: -40px;
  }



  input, textarea {
    background: #EEEEEE;
    border: none;
    border-radius: 6px;

    height: 52px;
    display: flex;
    align-items: center;
    padding: 0 16px;

    width: 100%;
    resize: none;

    transition: .3s;



    &:focus, &:active {
      outline-color: $blue;

    }

    &:disabled{
      opacity: .5;
      cursor:  not-allowed;
    }
  }
}