
.wrap {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .filtersWrap {
    display: flex;
    align-items: center;
    gap: 32px;

    .dates {
      width: 390px;
      display: flex;
      gap: 8px;
      align-items: center;
    }

    .selector {
      flex: 1;
    }
  }
}


.searchDates {
  display: flex;
  align-items: center;
  gap: 8px;
}