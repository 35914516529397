@import "./src/static/colors";

.header {

  margin-bottom: 48px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: center;
  width: 100%;

  a {
    color: $aliceBlue;
  }
}

.stickyWrap {
  position: sticky;
  top: 0;
  min-height: 100vh;
  max-height: 100vh;
}

.wrap {
  background: $blue;
  min-width: 265px;
  max-width: 265px;
  height: 100%;
  box-sizing: border-box;
  padding: 32px 8px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  transition: .3s;
  flex-grow: 0;

  position: relative;


  &.small {
    width: auto;
    min-width: 0;
  }
}

.exitButton {
  margin-top: 32px;
}

.flexCol {
  display: flex;
  flex-direction: column;
}

.justifyBetween {
  justify-content: space-between;
}

.linksWrap {
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex-grow: 1;
  position: relative;
}


.hideMenu {
  width: 40px;
  height: 40px;
  outline: none;

  cursor: pointer;
  transition: .3s;


  &.active {
    transform: rotate(180deg);
  }


  &:hover {
    opacity: 1;
  }

  background: none;
  border: none;

  svg {
    height: 20px;
    width: 20px;

    path {
      fill: $white;
    }
  }
}

.logo {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 0 16px;

  img {
    height: 48px;
  }
}
