@import "./src/static/colors";

.wrap{
  display: flex;
  flex-direction: column;

  .table{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 24px;

    .statCardWrap{
      display: flex;
      padding: 32px;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      background: $line2;
      transition: .3s;
      border-radius: 12px;

      &:hover{
        background: $aliceBlue;
      }
    }

    .statInfoWrap{
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .cardTitle{
        color: $blue;
        margin-top: 8px;
      }

      .cardCounter, span{
        color: $blue;
      }

      .cardAction{
        margin-right: -16px;
      }
    }
  }
}

