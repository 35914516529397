@import "./src/static/colors";

.uploadIconWrap {
  margin-top: 16px;
  padding: 32px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%233C4AE4FF' stroke-width='4' stroke-dasharray='14' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
  border-radius: 6px;
  display: flex;
  align-items: center;
  cursor: pointer;

  .videoView {
    width: 320px;
    height: 180px;
  }

  input {
    display: none;
  }

  .iconPreview {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      background: $blue;
    }
  }

  p {
    margin-left: 16px;
  }
}