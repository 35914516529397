@import "./src/static/colors";

.wrap {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 24px;


  .themeColumn {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .storyArray {
    display: flex;

    width: 100%;
    background: $line2;
    border-radius: 6px;
    padding: 12px 6px;

    overflow: auto;
    margin-right: 24px;
  }
}


.editWrap {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;

  padding: 24px;

  background: #FFFFFF;
  border: 1px solid #E6F1FF;
  box-shadow: 0 11px 22px -12px rgba(60, 74, 228, 0.25);
  border-radius: 6px;
  margin-right: 24px;

  position: relative;

  .editHeadline {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }


  .editIsLoading {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    border-radius: 6px;

    z-index: 1000;
  }
}


.storyCard {
  display: flex;
  flex-direction: column;
  position: relative;
  transition: .3s;
  width: 140px;
  box-sizing: border-box;


  &.isDeleting {
    opacity: 0;
    width: 0;
    max-height: 1px;
    margin-right: 0;
    overflow: hidden;
  }

  &:after {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 1) 80%);
    opacity: .5;
    height: 32px;
    content: "";
    z-index: 1;
  }


  .storyImg {
    height: 250px;
    width: 100%;
    border-radius: 6px;
    object-fit: cover;
    background-color: $aliceBlue;


  }

  .storyInfo {
    display: flex;
    margin-top: 8px;
  }

  .buttons {
    position: absolute;
    right: 8px;
    top: 8px;
    z-index: 10;

    button {
      width: 20px;
      height: 20px;
      margin-left: 8px;
      background: none;
      border: none;
      cursor: pointer;
      transition: .3s;

      &:hover {
        transform: rotate(15deg) scale(1.1);
      }


      &:active {
        transform: translateY(2px);
      }
    }
  }
}

.editCol {
  flex-shrink: 0;
}

.storiesList {
  display: flex;

  .addNewWrap {
    width: 140px;
    height: 100%;
    min-height: 300px;
    flex-shrink: 0;


    .addNewButton {
      background-color: transparent;
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%233C4AE4FF' stroke-width='4' stroke-dasharray='14' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
      border-radius: 6px;
      border: none;

      cursor: pointer;

      width: 140px;
      height: 100%;
      min-height: 300px;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;

      &:active {
        p {
          transform: translateY(2px);
        }
      }

      p {
        transition: .3s;
        text-align: center;
        margin-top: 16px;
      }
    }
  }


  .cardWrap {
    padding: 0 6px;
  }
}

.storyListCaption {
  text-align: center;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%23FFB8BAFF' stroke-width='4' stroke-dasharray='14' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
  border-radius: 6px;
  border: none;

  color: $lightPink;

  padding: 8px 16px;
}

