@import "./src/static/colors";

.wrap {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 24px;


  .header {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;

      width: 100%;
    }
  }
}


.graph {
  display: flex;
  flex-direction: column;
  gap: 24px;
  grid-column-start: 1;
  grid-column-end: 3;

  background: $white;
  border: 1px solid $aliceBlue;
  border-radius: 12px;
  padding: 16px;
}

.info {
  padding: 16px;
  background: $white;
  border: 1px solid $aliceBlue;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 24px;


  .column {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .row {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      width: 100%;

      p {
        flex-shrink: 0;
      }

      .separtor {
        border-bottom: 1px dashed $lightPink;
        width: 100%;
        flex-shrink: 1;
        flex-grow: 0;
        margin: 0 4px;
      }
    }
  }
}

.headerWrapper{
  display: flex;
  align-items: center;
  gap: 8px;
}