.wrap {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .headline {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .tableGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;
  }
}

.tableWrap {
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-height: 500px;
}

.line {
  display: flex;
  align-items: center;
  gap: 16px;
}
