@import "./src/static/colors";

.wrapper {
  display: flex;
  position: relative;
}

.visible {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  height: 24px;
  width: 24px;
  border-radius: 100%;

  border: 3px solid $aliceBlue;
  background: white;
  transition: .3s;
  color: $blue;

  &:hover{
    translate: 0 2px;
  }

}


.hiddenPart {
  display: flex;
  z-index: 10000;
  flex-direction: column;
  padding: 12px;
  border-radius: 4px;
  background: $white;
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
  border: 1px solid $aliceBlue;
  box-shadow: 5px 5px 10px rgba(60, 74, 228, 0.1);
  width: 350px;

  transition: .3s;
  opacity: 0;
  pointer-events: none;

  p{
    color: $eerieBlack !important;
    line-height: 120%;
  }

  &.active {
    opacity: 1;
    pointer-events: auto;


  }
}