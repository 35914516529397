@import "./src/static/colors";

.img {
  border-radius: 100%;
  height: 50px;
  width: 50px;
}


.innerContent {
  z-index: 1;
  border-radius: 12px;
}


.status {
  padding: 4px;
  text-transform: uppercase;
  width: max-content;
  border-radius: 3px;
  color: $white;

  &.blocked {
    background: $radicalRed;
  }

  &.active {
    background: $honeyYellow;
  }
}



