@import "../../../../static/colors";

.radio {
  display: none;
}


.radioGroupWrap {
  display: flex;
  margin-top: 16px;

  &.big {
    .radioLabel {
      box-sizing: border-box;
      display: flex;
      color: $blue;
      background: $white;
      cursor: pointer;
      transition: .2s;
      flex-grow: 0;
      flex-shrink: 0;
    }

    .checkmark {
      padding: 24px 16px;
      border: 3px solid $blue;
      border-radius: 12px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 163px;
      width: 163px;
      transition: .3s;

      &:hover {
        background: $blue;
        color: $white;

        .title {
          color: white;
        }

        svg path {
          fill: $white;
        }
      }

      .icon {
        height: 50px;
        width: 50px;
        display: flex;
        align-items: center;
      }

      svg {
        object-fit: contain;

        path {
          transition: .3s;
        }
      }
    }

    .radio:checked ~ .checkmark {
      background: $blue;
      color: $white;

      svg path {
        fill: $white;
      }

      .title {
        color: white;
      }
    }

    .title {
      font-size: 16px;
    }

    .singleRadioWrap {
      &:not(:last-child) {
        margin-right: 16px;
      }
    }
  }

  &.small {
    .radioLabel {
      display: flex;
      cursor: pointer;
      margin-right: 32px;

      .checkmark {
        display: flex;
        align-items: center;
      }

      .radio:checked ~ .checkmark .dot:after {
        opacity: 1;
      }
    }

    .title {
      font-weight: 600;
      font-size: 16px;
      margin-left: 24px;
    }


    .dot {
      height: 25px;
      width: 25px;
      border-radius: 100%;
      border: 1px solid $blue;
      position: relative;

      &:after {
        transition: .3s;
        content: '';
        position: absolute;
        height: 15px;
        width: 15px;
        border-radius: 100%;
        background: $blue;
        top: 4px;
        left: 4px;
        opacity: 0;
      }
    }
  }
}




