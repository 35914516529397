@import "./src/static/colors";

.emptyList {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 175px;

  border-radius: 12px;
  background: $line2;
}
