@import "./src/static/colors";

.chatWrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 100%;
  height: 100%;

  border-radius: 12px;
  overflow-y: scroll;
  background: $line2;
  padding: 16px;

  flex-grow: 1;

}

.messageBlockWrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  max-width: 80%;
  margin-bottom: 20px;
  gap: 16px;

  .messagesWrap {
    display: flex;
    flex-direction: column;

  }

  .personInfo {
    display: flex;
    flex-direction: column;
    max-width: 50px;

    img {
      width: 50px;
      height: 50px;
      margin-bottom: 8px;
      border-radius: 100%;
      background: $aliceBlueHover;
    }

    .messageTime {
      color: $eerieBlack;
      text-align: center;
    }
  }
}

.messageWrap {
  position: relative;
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 4px;
  }

  .message {
    padding: 16px;
    border-radius: 6px;
    background: $aliceBlue;
    width: fit-content;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    background: $white;
    color: $eerieBlack;
    text-align: left;

    p {
      word-break: break-word;
    }

    .attachmentsDocumentsWrap {
      display: flex;
      gap: 8px;
      margin-bottom: 16px;

      .attPreview {
        height: 75px;
        width: 75px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        background: $white;
      }

      &.blue {
        align-self: flex-end;
        color: $white;
      }
    }

    .attachmentsWrap {
      display: grid;
      grid-gap: 4px;
      grid-template-columns: 40px 40px 40px 40px 40px;
      grid-template-rows: 30px;
      grid-auto-rows: 30px;
      margin-bottom: 8px;


      &.blue {
        align-self: flex-end;
        color: $white;


        path {
          fill: $white;
        }
      }

      .attPreview {
        height: 100%;
        width: 100%;

        &:first-child {
          grid-column-start: 1;
          grid-column-end: 6;
          grid-row-start: 1;
          grid-row-end: 5;
        }


        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 3px;
        }
      }
    }

    .messageStatus {
      display: flex;
      align-items: center;
      margin-top: 8px;
      opacity: .7;

      path {
        fill: black;
      }
    }

    &.selected {
      background: $aquaBlueHover !important;
    }

    p {
      color: inherit;
    }

    span {
      color: inherit;
      margin-right: 8px;
    }

    &.blue {
      align-self: flex-end;
      background: $blue;
      color: $white;

      .messageStatus {
        align-self: flex-end;

        path {
          fill: $white;
        }
      }
    }
  }

  .buttons {
    position: absolute;
    transition: .3s;
    right: calc(100% + 8px);
    display: flex;
    gap: 4px;
    pointer-events: none;
    opacity: 0;
    transform: translateY(5px);

    &.selected {
      pointer-events: auto;
      opacity: 1;
      transform: translateY(0);
    }

    button {
      height: 24px;
      width: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      background: none;
      border: none;
      transition: .3s;

      svg {
        height: 16px;
        width: 16px;

        path {
          fill: $black2;
        }
      }

      &:active {
        transform: translateY(2px);
      }
    }
  }
}