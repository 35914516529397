@import "./src/static/colors";

.wrap {
  position: fixed;
  top: 0;
  width: 300px;
  left: calc(50% - 150px);
  pointer-events: none;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 8px;
  z-index: 1000000;


  .message {
    background: $lightPink;
    padding: 8px;
    border-radius: 6px;
    margin-bottom: 8px;
    animation: slideIn .3s ease-in-out;
    z-index: 10000000;

    &.OK{
      background: $green;
    }
  }


}

@keyframes slideIn {
  from {
    transform: translateY(-50px) scale(0);
    opacity: 0
  }
  to {
    transform: translateY(0) scale(1);
    opacity: 1
  }
}