@import "./src/static/colors";


.button {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  background: none;
  border: none;
  cursor: pointer;
  transition: .3s;

  &:hover {
    transform: translateY(2px);
  }
}


.wrap {
  display: flex;
  flex-direction: column;
  width: 523px;

  gap: 16px;

  span{
    color: $blue;
  }

  .dates {
    display: flex;
    justify-content: space-between;
    gap: 16px;
  }
}
