.wrap {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px;
  background: #FFFFFF;
  border: 1px solid #E6F1FF;
  border-radius: 12px;

  .line{
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .selector {
      display: flex;
      align-items: center;
      gap: 24px;

      .types {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }

    .dates {
      display: flex;
      gap: 16px;
      align-items: center;
    }
  }
}
