@import "./src/static/colors";

.tabs {
  display: flex;
  align-items: center;

  .tabButton {
    margin-right: 16px;
    background: none;
    border: none;
    position: relative;
    transition: .3s;
    cursor: pointer;
    color: $black2;

    &.active {
      color: $blue;

      &:after {
        transform: scaleX(1);
        opacity: 1;
      }
    }

    &:after {
      bottom: -4px;
      left: 0;
      right: 0;
      position: absolute;
      height: 3px;
      background: $blue;
      transition: .3s;
      content: "";
      transform: scaleX(0);
      opacity: 0;
    }
  }
}