@import "./src/static/colors";

.wrap {
  display: flex;
  flex-direction: column;

  .top {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    justify-content: space-between;

    .tabNavs{
      display: flex;
      align-items: center;
    }


    .tabButton {
      margin-right: 16px;
      background: none;
      border: none;
      position: relative;
      transition: .3s;
      cursor: pointer;

      &.active {
        color: $blue;

        &:after {
          transform: scaleX(1);
          opacity: 1;
        }
      }

      &:after {
        bottom: -4px;
        left: 0;
        right: 0;
        position: absolute;
        height: 3px;
        background: $blue;
        transition: .3s;
        content: "";
        transform: scaleX(0);
        opacity: 0;
      }
    }
  }
}

.sideButtons {
  display: flex;
}