@import "./src/static/colors";

.wrap {
  display: grid;
  grid-gap: 24px;
  grid-template-columns: 2fr 1fr;

  .big {
    background: $white;
    border: 1px solid $aliceBlue;
    border-radius: 12px;
    padding: 16px;

    .radialHeader {
      color: $black2;
    }

    .tabs {
      display: flex;
      margin-bottom: 32px;

      button {
        margin-right: 24px;
        background: none;
        border: none;
        cursor: pointer;
        transition: .3s;
        position: relative;
        color: $black2;

        &:after {
          content: '';
          position: absolute;
          bottom: -4px;
          left: 0;
          height: 2px;
          width: 100%;
          background: $blue;
          transition: .3s;
          transform: scaleX(0);
          opacity: 0;
        }

        &.active {
          color: $blue;
          transform: translateY(-2px);

          &:after {
            transform: scaleX(1);
            opacity: 1;
          }
        }

        &:hover {
          transform: translateY(-2px);
        }

        &:active {
          transform: translateY(2px);
        }
      }
    }
  }
}

.finTop {
  display: flex;
  justify-content: space-between;

  .line {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .col {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}

.chartsWrap {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 16px;
  margin-top: 24px;

  .col {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

