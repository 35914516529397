@import "./src/static/colors";

.infoWrap {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.attFiles {
  display: flex;
  gap: 8px;
}

.modalImgPrev {
  width: 100px;
  height: 100px;
  object-fit: cover;
  cursor: pointer;
  background: $line2;
  border: none;

  img {
    width: 100%;
    height: 100%;
    border-radius: 6px;
  }
}

.fileIcon {
  height: 100px;
  width: 100px;
  border-radius: 4px;
  background: $line2;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.actionWrap {
  display: flex;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
}

.editButton {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  border-radius: 6px;
  background: $blue;
  border: none;
  cursor: pointer;
  transition: .3s;

  &:hover{
    background: $aquaBlueHover;
  }
}

.modalWrap{
  width: 524px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}