@import "./src/static/colors";

.small {
  padding: 16px;
  border-radius: 12px;

  background: $blue;

  .finTop {
    display: flex;
    justify-content: space-between;

    p, h4 {
      color: white;
      fill: white;
    }

    .col {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }

  .counter {
    display: flex;
    align-items: center;
  }

  tspan {
    color: white;
    fill: white;
  }
}

.headerWrapper{
  display: flex;
  align-items: center;
  gap: 8px;
}