@import "./src/static/colors";


.wrap {
  display: flex;
  flex-direction: column;
}


.tableTags {
  display: flex;
  margin-top: -4px;
  flex-wrap: wrap;

  .tag {
    margin-top: 4px;
    margin-right: 4px;
    padding: 4px 8px;
    background: $aliceBlue;
    border-radius: 6px;
    color: $blue;
  }
}

.avatar {
  height: 40px;
  width: 40px;
  border-radius: 3px;
  background: $aliceBlue;
}

