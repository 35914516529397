@import "../../../../static/colors";


.table {
  display: flex;
  flex-direction: column;
  margin-top: 24px;

  .tag {
    color: $blue;
    background: $aliceBlue;
    border-radius: 6px;
    padding: 4px 8px;
    min-width: 63px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
  }
}



