@import "./src/static/colors";

.wrap {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .filterWrap {
    display: flex;
    align-items: center;
    gap: 24px;
  }

  .dates{
    display: flex;
    gap: 24px;

  }
}