@import "../../../static/colors";

.wrap {
  display: flex;
  width: 100%;
  flex-direction: column;


  .topLine {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .tabs {
      display: flex;
      align-items: center;

      .tab {
        margin-right: 16px;
        border: none;
        background: none;
        cursor: pointer;
        transition: .3s;
        position: relative;

        &:after {
          content: "";
          position: absolute;
          bottom: -6px;
          left: 0;
          width: 100%;
          height: 3px;
          background: $blue;
          transition: .3s;
          transform: scaleX(0);
          opacity: 0;
        }

        &.active {
          color: $blue;

          &:after {
            opacity: 1;
            transform: scaleX(1);
          }
        }

        &:hover {
          color: $aquaBlueHover;
        }

        &:active {
          transform: translateY(2px);
        }
      }
    }
  }
}

.content {
  margin-top: 24px;
  display: flex;
}

.tableWrap {
  width: 100%;
}


.img {
  height: 40px;
  width: 40px;
  border-radius: 6px;
  object-fit: cover;
}


.tag {
  border-radius: 4px;
  padding: 4px;
  color: $white;
  width: fit-content;

  &.yellow {
    background: $honeyYellow;
  }

  &.blue {
    background: $blue;
  }

  &.pink {
    background: $radicalRed;
  }
}