@import "./src/static/colors";

.blockHeader{
  color: $aliceBlue;
  margin-left: 16px;
}

.wrap{
  display: flex;
  flex-direction: column;
  gap: 16px;

  .links{
    display: flex;
    flex-direction: column;
  }
}
