@import "./src/static/colors";


.wrapper{
  display: flex;
  align-items: center;
  padding: 8px 16px;
  transition: .3s;
  border-radius: 6px;

  &.small{
    padding: 8px;
  }

  .icon{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.selected{
    background: $aliceBlue;

    .text{
      color: $blue;
    }


    .icon svg path{
      fill: $blue
    }
  }

  &:hover{
    background: $aliceBlue;

    .text{
      color: $blue;
    }

    .icon svg path{
      fill: $blue
    }
  }
}

.text{
  color: $aliceBlue;
  margin-left: 8px;
  max-width: 100%;
}

.icon{
  width: 24px;
  height: 24px;

  svg path{
    transition: .3s;
  }
}