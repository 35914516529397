.wrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
}

.listTable{
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-height: 500px;
}