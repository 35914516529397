
.infoWrap {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.users {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
}