@import "./src/static/colors";

.stageWrap {
  width: 824px;
  //height: 400px;
  flex-shrink: 0;
  border-radius: 12px;
}

.stagesWrap {
  width: 824px;
  overflow-x: hidden;

}

.stages {
  display: flex;
  transition: .5s;
  margin-left: -824px;
}

.stageIndicators {
  display: flex;
  margin-bottom: 8px;


  .stageIndicator {
    height: 5px;
    width: 30px;
    border-radius: 12px;
    background: $aliceBlue;
    border: 1px solid $blue;
    transition: .3s;

    &.active {
      background: $blue;
    }

    &:not(:last-child) {
      margin-right: 4px;
    }
  }
}