.wrap {
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-height: 600px;

  .headline {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

.profileAvatar {
  height: 40px;
  width: 40px;
  border-radius: 6px;
  object-fit: cover;
}