@import "./src/static/colors";

.allWrap{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.wrap{
  display: flex;
  align-items: center;
  margin-top: 16px;
  justify-content: flex-end;
}

.button{
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  user-select: none;

  margin-right: 8px;

  &:last-child{
    margin-right: 0;
  }

  transition: .2s;

  &:hover{
    background: $aliceBlue;
    color: $blue;
  }

  &.active{
    background: $aliceBlue;
    color: $blue;
  }
}

.queryInfo{
  display: flex;
  margin-top: 16px;
  align-items: center;
}

.offsetSetter{
  display: flex;
  align-items: center;
  margin-left: 32px;
}


.queryCountSwitch{
  display: flex;
  align-items: center;

  button{
    background: none;
    border: none;
    text-decoration: underline;
    padding: 4px;
    cursor: pointer;

    &.activeCounter{
      font-weight: 800;
    }
  }
}