@import "./src/static/colors";

.wrap {
  width: 100%;
}

.img {
  height: 40px;
  width: 40px;
  border-radius: 6px;
  background: $aliceBlue;
  object-fit: cover;
}

.tag {
  padding: 4px;
  border-radius: 3px;
  text-transform: uppercase;
  color: $white;
  width: max-content;

  &.blueTag {
    background: $blue;
  }

  &.yellowTag {
    background: $honeyYellow;
  }

  &.blackTag {
    background: $black2;
  }
}
