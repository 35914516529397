@import "./src/static/colors";

.wrap {
  width: 824px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: 80vh;
  overflow: auto;
}

.rowWrap {
  display: grid;
  grid-template-columns: 56px 1fr 24px;
  width: 100%;
  gap: 16px;
  align-items: center;
  padding: 8px;
  border-radius: 4px;

  span {

  }


  &.primary {
    background: $aliceBlue;
  }


}

.cardIcon {
  display: flex;
  align-items: center;
}

.deleteButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background: none;
  border: none;
  cursor: pointer;
}

.buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
}

.modalWrap {
  width: 824px;
  max-height: 80vh;
  overflow: auto;
}

.iconRow {
  display: flex;
  gap: 8px;
  align-items: center;
}

.tag {
  display: flex;
  padding: 4px;
  border-radius: 3px;
  font-size: 12px;
  color: white;
  text-transform: uppercase;
  width: fit-content;

  &.yellow {
    background: $honeyYellow;
  }

  &.red {
    background: $radicalRed;
  }
}

.deleteModal {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 824px;
}