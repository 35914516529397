@import "./src/static/colors";

.wrapStage {
  width: 824px;
  display: flex;
  flex-direction: column;
}

.wrapStage1Inputs {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
  margin-bottom: 32px;
}

.doubleRow {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
}

.tripleRow {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 24px;
}

.description {
  margin-bottom: 16px;
  color: $text2;
}

.wrongPasswordMessage {
  position: absolute;
  top: 0;
  right: 0;
}