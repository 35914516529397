@import "./src/static/colors";

.wrap {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.headLine {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}


.paginationInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.queryInfo {
  display: flex;
  margin-top: 16px;
  align-items: center;
}

.offsetSetter {
  display: flex;
  align-items: center;
  margin-left: 32px;
}

.queryCountSwitch {
  display: flex;
  align-items: center;

  button {
    background: none;
    border: none;
    text-decoration: underline;
    padding: 4px;
    cursor: pointer;

    &.activeCounter {
      font-weight: 800;
    }
  }
}

.searchBar {
  display: flex;
  align-items: center;
  gap: 16px;

  label {
    width: 400px;
  }
}

.statusSelector {
  display: flex;
  align-items: center;

  p {
    margin-right: 16px;
  }
}

