
.reviewsWrap {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.reviews {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;


  .reviewBlock {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .titleRow {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;


      .rate {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }
  }
}