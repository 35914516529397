@import "src/static/colors";

.wrap {
  position: relative;
}

.counter {
  position: absolute;
  right: 24px;
  top: 36px;
  color: $blue;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  background: $white;
  opacity: .2;
  border-radius: 12px;
}