@import "./src/static/colors";


.wrap{
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  width: 524px;
}

.selectedSubjectLabel{
  color: $blue;
}