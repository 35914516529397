@import "./src/static/colors";

.wrap {
  height: 24px;
  width: 35%;
  border-radius: 4px;
  background: $aliceBlue;
  position: relative;
  overflow: hidden;

  &:after {
    position: absolute;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #D4E7FF 100%);
    width: 20px;
    height: 24px;
    content: '';
    left: 0;
    top: 0;
    transition: .3s;

    animation: slide 2s infinite ease-in-out;
  }
}

@keyframes slide {
  0%{
    left: -20px;
  }

  100%{
    left: 100%;
  }

}